<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string
    color?: string
    size?: string | number
    class?: string
    width?: string
    height?: string
  }>(),
  {
    color: '67707B',
    size: 2
  }
)

function getUnitValue(value: string | number) {
  return /(px|em|rem|%)$/.test(value.toString()) ? value : `${value}rem`
}

// icon尺寸定义
const iconSize = computed(() => {
  return props.width && props.height
    ? {
        width: getUnitValue(props.width),
        height: getUnitValue(props.height)
      }
    : {
        width: getUnitValue(props.size),
        height: getUnitValue(props.size)
      }
})

// iconName 默认为导入图片name
const iconName = computed(() => `#icon-${props.name}`)
</script>

<template>
  <svg
    ha
    wa
    cursor-pointer
    overflow-hidden
    text-center
    v-bind="$attrs"
    :style="{ color, fill: color, ...iconSize }"
    aria-hidden="true"
  >
    <use :xlink:href="iconName" />
  </svg>
</template>
